<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>ico-N/ico-24-tel.gray</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="ico-N/ico-24-tel.gray" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        d="M6.85633087,3.07274556 L9.39442936,7.35601935 C9.45729611,7.51953913 9.36587476,7.70802359 9.12016531,7.92147272 C8.75160114,8.24164643 7.29716629,9.39055986 7.07674858,9.99522884 C6.85633087,10.5998978 7.17980079,12.2675469 9.53796742,14.450118 C11.8961341,16.6326891 13.4286119,17.1398144 13.7903963,16.8862518 C14.0315859,16.71721 14.6932895,16.0874277 15.7755072,14.9969048 C16.0398951,14.7472405 16.2993845,14.6662508 16.5539753,14.7539355 C16.9358614,14.8854627 20.4770097,16.2779387 20.8175227,16.5082807 C21.1580356,16.7386227 21.3293091,17.5194804 20.5265867,19.0478904 C19.7238642,20.5763003 18.1699287,21.0885952 16.3886508,21.0885952 C15.2011322,21.0885952 14.3350474,20.9035363 13.7903963,20.5334184 C11.2294021,19.3478096 9.04643553,17.8409253 7.24149651,16.0127657 C4.53408799,13.2705262 3,10.4144716 3,7.48121269 C3,4.54795374 4.61985183,3.21823669 5.38003133,3.07274556 C5.88681766,2.97575148 6.37891751,2.97575148 6.85633087,3.07274556 Z"
        id="Path-14"
        stroke="currentColor"
        stroke-width="2"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IcoPhone',
}
</script>

<style lang="scss" scoped></style>
